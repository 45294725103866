<template>
    <div class="container_con">
        <el-card>
            <div class="new_search_box flex">
              <div style="margin-top:0px">
                  <span class="mr_20">
                    时间：
                    <el-date-picker
                      v-model="queryParams.time"
                      type="daterange"  value-format="YYYY-MM-DD" 
                      placeholder="请选择时间"
                      range-separator="至" style="width: 300px;"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </span>
                  <span class="mr_20">
                    单号：
                    <el-input
                      v-model="queryParams.inventory_no"
                      placeholder="请输入单号"
                      style="width:240px"
                    />
                  </span>
                  
                <el-button type="primary" @click="handleQuery()">搜索</el-button>
                <el-button @click="resetQuery()">重置</el-button>
                
              </div>
            </div>
            <div class="search">
                <div>
                    <el-button type="primary" @click="$router.push('/addBlitem')" v-if="have_auth('/blitem/add')">+ 新增</el-button>
                    <el-button type="primary" icon="el-icon-upload2" v-if="have_auth('/blitem/export')" :loading="is_export" @click="try_export">导出</el-button>
                </div>
            </div>
            <div style="margin-top:20px">
                <el-table :data="list" v-loading="loading" style="width: 100%" >
                    <el-table-column prop="inventory_date" label="开票时间" />
                    <el-table-column prop="inventory_no" label="单据编号" />
                    <el-table-column prop="department_name" label="部门" />
                    <el-table-column prop="admin_name" label="业务员" />
                    <el-table-column prop="warehouse_name" label="盘点货位" />
                    <el-table-column prop="warehouse_no" label="货位编号" />
                    <el-table-column label="产品编号">
                        <template v-slot="scope">
                            {{scope.row.inventory_product[0].product?scope.row.inventory_product[0].product.product_no:''}}
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="50">
                        <template v-slot="scope">
                            <!-- <el-button type="text" size="small" @click="handleUpdate(scope.row)"  v-if="have_auth('/blitem/edit')">编辑</el-button> -->
                            <el-button type="text" size="small"  @click="handleDetail(scope.row)" v-if="have_auth('/blitem/view')">查看</el-button>
                            <!-- <el-button type="text" size="small" @click="handleDelete(scope.row)"  v-if="have_auth('/blitem/del')">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    />
</template>

<script>
    export default {
        name: "department",
        data() {
            return {
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    inventory_no: '',
                    time:''
                },
                total:0,
                list:[],
                is_export:false,
            };
        },
        created() {
            this.queryParams=window[this.$route.path] || {
              page: 1,
              size: 10,
              inventory_no: '',
              time:''
            }
            this.getList();
        },
        watch: {
          $route(to,form) {
              window[form.path]=this.queryParams
          },
        },
        methods: {
            // 查看详情
            handleDetail(row){
                this.$router.push('/blitemDetail?id='+row.id)
            },
            resetQuery(){
                this.queryParams= {
                    page: 1,
                    size: 10,
                    inventory_no: '',
                    time:''
                }
                this.getList()
            },
            try_export(){
                if(this.is_export){return false;}
                this.is_export = true;
                let _data={...this.queryParams};
                _data.startTime=_data.time?_data.time[0]:'';
                _data.endTime=_data.time?_data.time[1]:'';
                this.$httpGet("/backend/Inventory/export", _data).then((res) => {
                    if (res.status == 200) {
                          var a = document.createElement('a');
                          let name=res.data.file.split('/')
                          var fileName = name[name.length-1];
                          a.download = fileName;
                          a.href = this.$http+'/'+res.data.file;
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                    } else {
                        this.$message.error(res.message);
                    }
                    this.is_export = false;
                }).catch((err) => {
                    console.log(err);
                    this.is_export = false;
                });
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
              this.$router.push('/addBlitem?id='+row.id)
            },
            /** 删除按钮操作 */
            handleDelete(row) {
              const _this=this;
              const ids = row.id || this.ids;
              this.$confirm('是否确认删除?', "警告", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(function() {
                  
                  _this.$httpGet("/backend/Inventory/del", {ids:ids}).then((res) => {
                      if (res.status == 200) {
                          _this.getList();
                          _this.$message.success(res.message);
                      } else {
                          _this.$message.error(res.message);
                      }
                  }).catch((err) => {
                      console.log(err);
                  });
                })
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                let _data={...this.queryParams};
                _data.startTime=_data.time?_data.time[0]:'';
                _data.endTime=_data.time?_data.time[1]:'';
                this.$httpGet("/backend/Inventory/index", _data).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>

<style lang="scss" scoped></style>
